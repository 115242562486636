<template>
  <span>
    <v-data-table
      v-model="thisSelected"
      :headers="headers"
      :footer-props="{
        'items-per-page-options': [20, 30, 50],
      }"
      :items="tags"
      :loading="loading"
      show-select
      :item-class="rowClass"
      checkbox-color="msaBlue"
      class="tags-table"
      @item-selected="onSelect($event)"
      @toggle-select-all="onSelect($event)"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-row no-gutters>
          <v-col cols="12" class="font-weight-medium">
            <span @click="goToDetail(item)" class="clickable">
              {{ item.name }}
            </span>
          </v-col>
          <v-col class="text-caption">
            {{ item.description }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.formCount`]="{ item }">
        <span
          class="msaBlue--text"
          style="cursor: pointer"
          @click="goToForms(item.id)"
        >
          {{ item.formCount }}
        </span>
      </template>
      <template v-slot:[`item.addedOn`]="{ item }">
        {{ item.addedOn | filterAsLocalDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="goToDetail(item)"
              text
              small
              color="msaBlue"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip v-if="item.isArchive" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="activateTag(item)"
              text
              small
              color="warning"
              v-on="on"
            >
              <v-icon>mdi-file-cabinet</v-icon>
            </v-btn>
          </template>
          <span>Activate</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="archiveTag(item)"
              text
              small
              color="msaBlue"
              v-on="on"
            >
              <v-icon>mdi-file-cabinet</v-icon>
            </v-btn>
          </template>
          <span>Archive</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </span>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      default() {
        return {
          name: '',
          archived: 0,
        };
      },
    },
    groupId: {
      type: Number,
    },
    selected: {
      type: Array,
    },
    itemCount: {},
    type: Number,
  },
  data() {
    return {
      tags: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Forms Attached',
          value: 'formCount',
          class: 'lightGrey',
          align: 'center',
          width: '140px',
          sortable: true,
        },
        {
          text: 'Added By',
          value: 'addedBy',
          class: 'lightGrey',
          align: 'center',
          width: '180px',
        },
        {
          text: 'Added On',
          value: 'addedOn',
          class: 'lightGrey',
          align: 'center',
          width: '120px',
        },
        {
          text: 'Actions',
          value: 'actions',
          class: 'lightGrey',
          align: 'center',
          sortable: false,
          width: '140px',
        },
      ],
      loading: false,
      thisSelected: [],
    };
  },
  watch: {
    groupId() {
      this.getTags();
    },
    filters: function () {
      this.getTags();
    },
    selected() {
      this.checkSelected();
    },
  },
  methods: {
    goToDetail(tag) {
      //this is here so the crumbs don't look wonky on the next page
      this.$store.commit('setSelectedTagName', tag.name);
      this.$router.push({
        name: 'FormTagDetail',
        params: { id: tag.id },
      });
    },
    getTags() {
      this.loading = true;
      const params = {
        filter: this.filter,
        tagGroupId: this.groupId,
      };

      const url = 'get-tags-by-group?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.tags = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          return error;
        });
    },
    activateTag(item) {
      this.loading = true;
      const params = {
        tagId: item.id,
        loaderText: 'Activating...',
      };

      const url = 'activate-tag?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.removeActive(item);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          return error;
        });
    },
    archiveTag(item) {
      this.loading = true;
      const params = {
        tagId: item.id,
        loaderText: 'Archiving...',
      };

      const url = 'archive-tag?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.removeArchived(item);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          return error;
        });
    },
    removeActive(item) {
      //remove active is screen is only showing archived
      if (this.filter.archived != 1) {
        item.isArchive = 0;
        return;
      }
      this.deselectOne(item);
      this.$emit('activate', this.groupId);
      this.tags = this.tags.filter((i) => i.id != item.id);
    },
    removeArchived(item) {
      //only remove if the screen is not showing archives
      if (this.filter.archived != 0) {
        item.isArchive = 1;
        return;
      }
      this.deselectOne(item);
      this.$emit('archive', this.groupId);
      this.tags = this.tags.filter((i) => i.id != item.id);
    },
    deselectOne(item) {
      this.$emit('onSelect', {
        items: [item],
        value: false,
      });
    },
    checkSelected() {
      this.thisSelected = this.tags.filter((item) =>
        this.selected.some((selected) => selected.id == item.id),
      );
    },
    onSelect(event) {
      if (event.item != undefined) {
        this.onSelectOne(event);
      }

      // Handle event when user uses SHIFT to select multiple items
      if (event.currentItem != undefined) {
        event.item = event.currentItem;
        this.onSelectOne(event);
      }

      if (event.items != undefined) {
        this.onSelectAll(event);
      }
    },
    onSelectOne(event) {
      this.$emit('onSelect', {
        items: [event.item],
        value: event.value,
      });
    },
    onSelectAll(event) {
      if (event.value) {
        this.thisSelected = event.items;
      } else {
        this.thisSelected = [];
      }
      this.$emit('onSelect', {
        items: event.items,
        value: event.value,
      });
    },
    rowClass(item) {
      if (item.isArchive) {
        return 'warning--text';
      }
      return 'msaBlue--text';
    },
    goToForms(tagId) {
      this.$router.push({
        name: 'CompanyForms',
        query: {
          tagIds: [tagId].toString(),
          createdDateIntervalId: 1,
        },
      });
    },
  },
  mounted() {
    this.getTags();
  },
};
</script>

<style>
.tags-table .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
